.tablebox {
    overflow: scroll;
    height: 200px;
    max-height: 200px;
    width: 400px;
  }
  table {border: none;}
  .wrapper {
    text-align: center;
    width: 100%;
  }
  .main {
    display: inline-block;
    
    width: 80%;
  }
  .nachLinks {
    text-align: left;
    width: 80%;
  }
  .twoButtons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left:  10%;
  }

  button {
    padding-left:20px;
    padding-right:20px;
    height: 30px;
    background-color:#F15507;
    font-weight:bold;
    font-size:14px;

    border-color:black;
    border-width: 3px;
    border-radius: 7px;

  }
  h2 {
    color:#F15507;
  }









  