textarea {
    resize: none;
  }
  #dialogue {
    z-index: 10;
  }
  .wrapper {
    text-align: center;
    width: 100%;
  }
  .main {
    display: inline-block;
    width: 80%;
  }
  h2 {
    color:#F15507;
  }
  button {
    padding-left:20px;
    padding-right:20px;
    height: 30px;
    background-color:#F15507;
    font-weight:bold;
    font-size:14px;

    border-color:black;
    border-width: 3px;
    border-radius: 7px;

  }
.eingabefeld {
  width: 300px;
}
.nachLinks {
  text-align: left;
  width: 80%;
}
.nachRechts {
  text-align: right;
  width: 100%;
}
.twoButtons {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left:  10%;
}
table {
  margin: 0 auto; /* or margin: 0 auto 0 auto */
}