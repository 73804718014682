.wrapper {
    text-align: center;
    width: 100%;
  }
  .main {
    display: inline-block;
    
    width: 80%;
  }
  .nachLinks {
    text-align: left;
    width: 100%;
  }
  h2 {
    color:#F15507;
  }